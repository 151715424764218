import {gql} from 'apollo-angular';


export const SendTavuelRequest = gql`
mutation sendTavuelRequest($request: RequestInput) {
  sendTavuelRequest(request: $request)
}
`;

export const sendTavuelCVs = gql`
mutation sendTavuelCVs($request: ResourcesHumanInput) {
  sendTavuelCVs(request: $request)
}
`;
