import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {Apollo, ApolloModule} from 'apollo-angular';
import { NgModule } from '@angular/core';



import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [ApolloModule],
})
export class ApolloClientsModule {

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink) {

    this.apollo.createDefault(
      {
        link: this.httpLink.create({ uri: environment.api }),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
          },
        }
      },
    );
  }
}
