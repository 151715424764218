import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(  
    private http: HttpClient) { }
 
  notifySlack(message: object): Observable<any> {
   let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

   return this.http.post(environment.slackApi,
    `payload=${ JSON.stringify(message)}`, {
      headers: headers
    })
  }
}
