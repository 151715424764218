import {
  CloseScrollStrategy,
  Overlay,
  RepositionScrollStrategy,
} from "@angular/cdk/overlay";
import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";

const isToucheDevice = (): boolean => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

const scrollRepositionFactory = (
  overlay: Overlay
): (() => RepositionScrollStrategy) => {
  return () => overlay.scrollStrategies.reposition();
};

const scrollCloseFactory = (overlay: Overlay): (() => CloseScrollStrategy) => {
  return () => overlay.scrollStrategies.close();
};
declare let google;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
  providers: [
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: isToucheDevice()
        ? scrollCloseFactory
        : scrollRepositionFactory,
      deps: [Overlay],
    },
  ],
})
export class AppComponent implements OnInit {
  constructor(private afs: AngularFirestore) {}

  ngOnInit() {}
}
