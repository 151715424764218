import { State, Action, StateContext, Selector, NgxsOnInit } from "@ngxs/store";
import { Feedback, UI } from "../interface/ui";
import { Platform } from "@angular/cdk/platform";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SwUpdate } from "@angular/service-worker";
import { ApiService } from "../services/api.service";
import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { SlackService } from "../services/slack.service";

let win: any;

const feedbackCollection = environment.production ? "feedback" : "dev-feedback";

export class InstallApp {
  static readonly type = "[UI] InstallApp]";
}

export class CheckUpdated {
  static readonly type = "[UI] CheckUpdated]";
}

export class VerifyInstallPrompt {
  static readonly type = "[UI] VerifyInstallPrompt]";
}

export class SendTavuelRequest {
  static readonly type = "[UI] SendTavuelRequest]";
  constructor(public readonly request: any) {}
}
export class SendTavuelCVResource {
  static readonly type = "[UI] SendTavuelCVResource]";
  constructor(public readonly request: any) {}
}
export class SetLoading {
  static readonly type = "[UI] SetLoading]";
  constructor(
    public readonly loading: boolean,
    public readonly yProgress?: number
  ) {}
}

export class SendNewFeedback {
  static readonly type = "[Feedback] SendNewFeedback";
  constructor(
    public readonly feedback: Feedback,

    public readonly onSuccess: () => void
  ) {}
}

@State<UI>({
  name: "ui",
  defaults: {},
})
@Injectable()
export class UIState implements NgxsOnInit {
  window: any;

  @Selector()
  static device({ device }: UI) {
    return device;
  }

  @Selector()
  static install({ install }: UI) {
    return install;
  }

  @Selector()
  static share({ share }: UI) {
    return share;
  }

  constructor(
    private api: ApiService,
    public platform: Platform,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    private afs: AngularFirestore,
    private slack: SlackService
  ) {}

  async ngxsOnInit({ dispatch }: StateContext<UI>) {
    dispatch(new VerifyInstallPrompt());
    dispatch(new CheckUpdated());
  }

  @Action(SendTavuelRequest)
  SendTavuelRequest({}: StateContext<UI>, { request }: SendTavuelRequest) {
    return this.api.sendTavuelRequest(request).pipe(tap(({ data }) => {}));
  }

  @Action(SendTavuelCVResource)
  SendTavuelCVResource(
    {}: StateContext<UI>,
    { request }: SendTavuelCVResource
  ) {
    return this.api.sendTavuelCVs(request).pipe(
      tap(({ data }) => {
        this.snackBar.open(
          "Tu solicitud fue enviada con éxito, pronto nos pondremos en contacto...",
          "🧐",
          {
            duration: 3000,
            panelClass: ["custom-snackbar"],
          }
        );
      })
    );
  }

  @Action(VerifyInstallPrompt)
  verifyInstallPrompt({ patchState }: StateContext<UI>) {
    win = window.navigator;
    let share = false;
    if (win.share) {
      share = true;
    }
    window.addEventListener("beforeinstallprompt", (install: any) => {
      install.preventDefault();
      patchState({ install });
    });
    const { userAgent, platform } = navigator;
    patchState({
      device: { ...this.platform, userAgent, name: platform },
      share,
    });
  }

  @Action(CheckUpdated)
  checkUpdated({}: StateContext<UI>) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        const snackBarRef = this.snackBar.open(
          "Nueva versión disponible  😄 ",
          "OK",
          {
            panelClass: ["custom-snackbar", "background-white"],
          }
        );
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      });
    }
  }

  @Action(InstallApp)
  async installApp({ getState, patchState }: StateContext<UI>) {
    const { install } = getState();
    if (install !== undefined) {
      install.prompt();
      const choiceResult = await install.userChoice;
      if (choiceResult.outcome === "dismissed") {
        console.log("User cancelled home screen install");
      } else {
        console.log("User added to home screen");
      }
      patchState({ install: null });
    }
  }

  @Action(SendNewFeedback)
  async SendNewFeedback(
    {}: StateContext<Feedback>,
    { feedback, onSuccess }: SendNewFeedback
  ) {
    const id = this.afs.createId();
    const dateform = new Date();
    feedback.createdAt = dateform.toLocaleString("en-GB");

    try {
      await this.afs
        .collection(feedbackCollection)
        .doc(id)
        .set({
          id,
          ...feedback,
        });
      onSuccess();

      const slackMsg = {
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: "Nuevo Feedback",
              emoji: true,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: "*Mensaje:*\n" + feedback["feedback"],
              },
              {
                type: "mrkdwn",
                text: "*Tipo de feedback:*\n" + feedback["feedbackType"],
              },
            ],
          },
        ],
      };

      this.slack.notifySlack(slackMsg).subscribe((response) => {});

      this.snackBar.open("¡Feedback enviado correctamente!", "😄", {
        duration: 3000,
        panelClass: ["snackbar"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } catch {
      this.snackBar.open("Campos sin rellenar", "🧐", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
