// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api:  'http://localhost:5000', // 'https://api.tavuel.com/nodejs', // 'http://localhost:5000',
  production: false,
  slackApi: 'https://hooks.slack.com/services/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', 
// Develop
  firebase : {
    apiKey: 'AIzaSyBCmf8_txLRHVyL9n8tu225fsEiNo9631c',
    authDomain: 'tavuel-pwa.firebaseapp.com',
    databaseURL: 'https://tavuel-pwa.firebaseio.com',
    projectId: 'tavuel-pwa',
    storageBucket: 'tavuel-pwa.appspot.com',
    messagingSenderId: '306205436772',
    appId: '1:306205436772:web:ea68559f857c717c122871',
    measurementId: 'G-FXZCF4MFN2'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
