import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';

import { HttpClient } from "@angular/common/http";
import { sendTavuelCVs, SendTavuelRequest } from './graphql/mutations';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(  
    private apollo: Apollo,
    private http: HttpClient) { }

  context = (token) => {
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
    return {};
  }
  
  sendTavuelRequest = (request) =>
    this.apollo.mutate<any>({
      mutation: SendTavuelRequest,
      variables: { request },
    });

  sendTavuelCVs = (request) =>
    this.apollo.mutate<any>({
      mutation: sendTavuelCVs,
      variables: { request },
    });
  
}
