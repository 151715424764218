import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";

import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";

import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { ApolloClientsModule } from "./apollo-clients-module";
import { ApiService } from "./services/api.service";
import { SlackService } from "./services/slack.service";
import { AngularFirePerformanceModule } from "@angular/fire/performance";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { UIState } from "./states/ui.state";
import { UserState } from "./states/user.state";
import { AngularFireAuthModule } from "@angular/fire/auth";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ApolloClientsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsModule.forRoot([UIState, UserState]),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFirePerformanceModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
  ],
  exports: [MatSnackBarModule, MatToolbarModule, MatDialogModule],
  providers: [ApiService, SlackService],
  bootstrap: [AppComponent],
})
export class AppModule {}
