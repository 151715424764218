import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { auth } from "firebase";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import { Admin, User } from "../interface/ui";

export class Login {
  static readonly type = "[User] Login]";
  constructor(public readonly credentials: { Email: any; Password: any }) {}
}

export class GoogleLogin {
  static readonly type = "[User] GoogleLogin]";
  constructor() {}
}

export class GoogleLinkAccount {
  static readonly type = "[User] GoogleLinkAccount]";
  constructor() {}
}

export class SignOut {
  static readonly type = "[User] SignOut]";
  constructor() {}
}
@State<User>({
  name: "user",
})
@Injectable()
export class UserState {
  private itemsCollection: AngularFirestoreCollection<Admin>;

  constructor(
    private store: Store,
    private snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    public router: Router,
    private afs: AngularFirestore
  ) {}

  openSnackBar = (message: string, button: string, config?: any) => {
    return this.snackBar.open(message, button, config).afterDismissed();
  };
  getError = (error) => {
    return {
      "auth/user-not-found":
        "No existe ningún registro de usuario que corresponda al identificador proporcionado.",
      "auth/wrong-password": "La contraseña no es válida.",
      "auth/too-many-requests":
        "El acceso desactivado temporalmente por muchos intentos fallidos login. Puede restaurarlo restableciendo su contraseña o puede intentarlo más tarde.",
      "auth/argument-error": "El correo electronico debe ser valido",
    }[error];
  };

  @Action(SignOut)
  signOut({ patchState, dispatch }: StateContext<User>) {
    return this.afAuth.auth.signOut().then(() => {
      dispatch(new Navigate(["/admin"]));
    });
  }

  @Action(Login)
  login({ dispatch }: StateContext<User>, { credentials }: Login) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(credentials.Email, credentials.Password)
      .then((userCredentials) => {
        dispatch(new Navigate(["/admin/metrics"]));
      })
      .catch(({ message }) => {
        this.snackBar.open(message, "Ok", { duration: 3000 });
      });
  }

  @Action(GoogleLinkAccount)
  googleLlinkAccount({}: StateContext<User>, {}: GoogleLinkAccount) {
    var googleProvider = new auth.GoogleAuthProvider();
    this.afAuth.auth.currentUser
      .linkWithPopup(googleProvider)
      .then((result) => {
        var user = result.user;
        Swal.fire(
          `Su cuenta: ${user.email}`,
          "Se ha vinculado correctamente.",
          "success"
        );
      })
      .catch((error) => {
        this.snackBar.open(error, "Ok", { duration: 3000 });
      });
  }

  @Action(GoogleLogin)
  googleLogin({ dispatch }: StateContext<User>, {}: GoogleLogin) {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(
      (result) => {
        console.log("result", result.credential);
        var user = this.afAuth.auth.currentUser;
        this.itemsCollection = this.afs.collection<Admin>("admins");
        var admins = this.itemsCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              return a.payload.doc.data();
            })
          )
        );
        var found = null;
        admins.forEach((doc) => {
          found = doc.find((e) => e.email == user.email);
          if (found) {
            dispatch(new Navigate(["/admin/metrics"]));
            this.openSnackBar("Bienvenido", "😄", { duration: 3000 });
          } else {
            this.openSnackBar("Ups! No tienes los permisos suficientes", "🙁", {
              duration: 3000,
            });
          }
        });
      },
      (err) => {
        console.log(err);
        this.openSnackBar(
          "Intenta desde otro navegador o con nuestro inicio de sesión",
          "OK",
          { duration: 7000 }
        );
        throw new Error(err);
      }
    );
  }
}
