import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContainerComponent } from "./pages/metrics/container/container.component";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/metrics/metrics.module").then((m) => m.MetricsModule),
  },
  {
    path: "services",
    loadChildren: () =>
      import("./pages/services/services.module").then((m) => m.ServicesModule),
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
